import React, { Component } from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, LocationAutocompleteInput } from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from '../../util/sdkLoader';

export const seTranslates = {
  de: {
    homeDatepickerPlaceholder: 'Daten',
    locationFilterPlaceholder: 'Gebe den Standort bitte hier ein...',
  },
  en: {
    homeDatepickerPlaceholder: 'Dates',
    locationFilterPlaceholder: 'Type location here...',
  },
  fr: {
    homeDatepickerPlaceholder: 'Dates',
    locationFilterPlaceholder: "Type d'emplacement ici ...",
  },
};

export const LANGUAGE_KEY = 'lang';
export const DEFAULT_LOCALE = 'en';

const { LatLng, LatLngBounds } = sdkTypes;

// Styles
import css from './LocationFilterPopup.module.css';

const KEY_CODE_ESCAPE = 27;

class LocationFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      hasValue: false,
      language: null,
      isOpenMobilePlain: true,
    };

    this.filter = null;
    this.filterContent = null;
  }

  componentDidMount() {
    this.setState({
      language: localStorage.getItem(LANGUAGE_KEY),
    });
  }

  getPlaceholder = () => {
    return seTranslates[this.state.language].locationFilterPlaceholder;
  };

  handleBlur = event => {
    // FocusEvent is fired faster than the link elements native click handler
    // gets its own event. Therefore, we need to check the origin of this FocusEvent.
    if (!this.filter.contains(event.relatedTarget)) {
      this.setState({ isOpen: false });
    }
  };

  handleKeyDown = e => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  toggleOpen = enforcedState => {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  };

  handleChange = location => {
    if (location && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.handleSubmit({ location });
    }
  };

  handleClear = (e, form) => {
    const { history, urlQueryParams } = this.props;
    this.setState({ isOpen: false });
    // this.handleSubmit(currentSearchParams);
    form.change('location', '');

    // const { bounds, ...rest} = currentSearchParams;
    const {bounds, address, ...params} = urlQueryParams

    const searchParams = {
      ...params,
    };

    this.setState({ hasValue: false, isOpen: false }, () =>
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      )
    );
  };

  handleCancel = (e, form) => {
    const { initialValues, urlParam } = this.props;
    this.setState({ isOpen: false });
    // form.change('location', '');

    // this.handleSubmit(urlParam, initialValues);
  };

  handleSubmit = values => {
    const { currentSearchParams, urlQueryParams, history } = this.props;

    if (values && values.location) {
      const { location } = values;
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...urlQueryParams,
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };

      this.setState({ hasValue: true, isOpen: false }, () =>
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        )
      );
    } else {
      this.setState({ hasValue: false, isOpen: false }, () =>
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}))
      );
    }
  };

  mobileHandleSubmit = values => {
    const { urlQueryParams, history } = this.props;

    if (values && values.location) {
      const { location } = values;
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...urlQueryParams,
        ...originMaybe,
        address: search,
        bounds,
      };

      this.setState({ hasValue: true, isOpen: false }, () =>
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        )
      );
    }
  };

  toggleIsOpen() {
    this.setState(prevState => ({ isOpenMobilePlain: !prevState.isOpenMobilePlain }));
  }

  identity = v => v;

  render() {
    const {
      rootClassName,
      className,
      location,
      intl,
      showAsPopup,
      currentSearchParams,
      initialValues,
      resetAll,
    } = this.props;

    const hasInitialValues =
      !!initialValues && !!initialValues.location && initialValues.location.search;

    const { isOpen, hasValue } = this.state;
    const classes = classNames(rootClassName || showAsPopup ? css.root : css.mobileRoot, className);
    const label = intl.formatMessage({ id: 'LocationFilter.label' });
    const labelStyles =
      hasValue && location.search === '' ? css.label : hasValue ? css.labelSelected : css.label;
    const mobileLabelStyle = hasInitialValues
      ? classNames(css.labelText, css.mobileLabelSelected)
      : css.labelText;

    const clear = intl.formatMessage({ id: 'FilterForm.clear' });
    const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
    const submit = intl.formatMessage({ id: 'FilterForm.submit' });

    const locationPlaceholder = this.state.language
      ? this.getPlaceholder()
      : seTranslates[DEFAULT_LOCALE].locationFilterPlaceholder;

    return (
      <div
        className={classes}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        ref={node => {
          this.filter = node;
        }}
      >
        {showAsPopup && (
          <button className={labelStyles} onClick={() => this.toggleOpen()}>
            {label}
          </button>
        )}

        <FinalForm
          {...this.props}
          onSubmit={this.handleSubmit}
          render={formRenderProps => {
            const { handleSubmit, form, values } = formRenderProps;

            return showAsPopup ? (
              <Form
                onSubmit={handleSubmit}
                className={
                  isOpen
                    ? classNames(css.formWrapper, css.formIsOpen)
                    : classNames(css.formWrapper, css.formIsClosed)
                }
              >
                <Field
                  name="location"
                  format={this.identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.S
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      onChange(value);
                    };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInput
                        placeholder={locationPlaceholder}
                        input={searchInput}
                        resetAll={resetAll}
                        handleClear={this.handleClear}
                        form={form}
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        meta={meta}
                      />
                    );
                  }}
                />

                <div className={css.buttonsWrapper}>
                  <button
                    className={css.clearButton}
                    type="button"
                    onClick={e => this.handleClear(e, form)}
                  >
                    {clear}
                  </button>
                  <button
                    className={css.cancelButton}
                    type="button"
                    onClick={e => this.handleCancel(e, form)}
                  >
                    {cancel}
                  </button>
                  <button className={css.submitButton} type="submit">
                    {submit}
                  </button>
                </div>
              </Form>
            ) : (
              <Form className={classNames(css.customFormWrapper, css.formIsOpen)}>
                <div className={css.mobileButtonsWrapper}>
                  <label className={mobileLabelStyle} onClick={() => this.toggleIsOpen()}>
                    {label}
                  </label>

                  <button
                    className={css.mobileClearButton}
                    type="button"
                    onClick={e => this.handleClear(e, form)}
                  >
                    {clear}
                  </button>
                </div>
                <div
                  className={
                    this.state.isOpenMobilePlain ? css.locationVisible : css.locationHidden
                  }
                >
                  <Field
                    name="location"
                    format={this.identity}
                    render={({ input, meta }) => {
                      const { onChange, ...restInput } = input;

                      // Merge the standard onChange function with custom behaviur. A better solution would
                      // be to use the FormSpy component from Final Form and pass this.onChange to the
                      // onChange prop but that breaks due to insufficient subscription handling.S
                      // See: https://github.com/final-form/react-final-form/issues/159
                      const searchOnChange = value => {
                        onChange(value);
                      };

                      const searchInput = { ...restInput, onChange: searchOnChange };
                      return (
                        <LocationAutocompleteInput
                          className={css.mobileRoot}
                          placeholder={locationPlaceholder}
                          input={searchInput}
                          form={form}
                          handleClear={this.handleClear}
                          inputRef={node => {
                            this.searchInput = node;
                          }}
                          resetAll={resetAll}
                          meta={meta}
                        />
                      );
                    }}
                  />
                  <div className={css.mobileSubmitButtonContainer}>
                    <div
                      className={css.submitButton}
                      onClick={() => this.mobileHandleSubmit(values)}
                    >
                      {submit}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

LocationFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  liveEdit: false,
  currencyConfig: config.currencyConfig,
};

LocationFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  contentPlacementOffset: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilterPopup);

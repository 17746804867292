import React from 'react';
import css from './CategoriesPage.module.css';
import { Subcategory } from './Subcategory';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchListings } from '../SearchPage/SearchPage.duck';
import * as SearchPage from '../SearchPage/SearchPage';

const CategoryList = props => {
  const {
    cat,
    isSmaller,
    onClosePanel,
    urlQueryParams,
    history,
    sortConfig,
    filterConfig,
    currentQueryParams,
  } = props;

  const titleClass = isSmaller ? css.titleSmaller : css.titleBigger;
  const listClass = isSmaller ? css.listSmaller : css.listBigger;

  // <-- start subcategories -->//
  // sort subcategories alphabetically and put "Other" subcategory to the bottom
  const subcategories = cat.subcategories;
  const filteredSubCategories = subcategories?.filter(s => !s.other);
  const otherSubCategories = subcategories?.filter(s => s.other);
  filteredSubCategories.sort( (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const sortedSubCategories = [...filteredSubCategories, ...otherSubCategories];
  // <-- end subcategories -->//

  const allListings = props.listings ? props.listings : [];

  const listingsCount = k => {
    const suchListings = allListings.filter(l => l?.attributes?.publicData?.subcategory === k);
    return suchListings ? suchListings.length : 'listings count not known';
  };

  return (
    <React.Fragment>
      <div className={titleClass}>{cat.name}</div>
      <ul className={listClass}>
        {sortedSubCategories.map(s => {

          return (
            <Subcategory
              key={s.id}
              id={s.id}
              name={s.name}
              count={listingsCount(s.id)}
              isSmaller={isSmaller}
              onClosePanel={onClosePanel}
              history={history}
              urlQueryParams={urlQueryParams}
              sortConfig={sortConfig}
              filterConfig={filterConfig}
              currentQueryParams={currentQueryParams}
            />
          );
        })}
      </ul>
    </React.Fragment>
  );
};

CategoryList.defaultProps = {
  listings: [],
};


export default CategoryList;

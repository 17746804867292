import React from 'react';
import { Link } from 'react-router-dom';
import css from './CategoriesPage.module.css';
import {createResourceLocatorString} from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";
import {isAnyFilterActive} from "../../util/search";

export const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export const Subcategory = props => {
  const { myId, isSmaller, count } = props;
  const subcatLink = 's?pub_subcategory=' + props.id;

  const applyFilters = () => {
    const {  onClosePanel } = props;

    const { history, urlQueryParams, sortConfig, filterConfig, currentQueryParams } = props;
    const searchParams = { ...urlQueryParams, ...currentQueryParams, pub_subcategory: props.id };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();
  }

  return !isSmaller ? (
    <Link key={myId} to={subcatLink} className={css.subcategoriesLink}>
      <li key={myId} className={css.subcategory}>
        {props.name}
        <span className={count > 0 ? css.countYellow : css.count}>({count})</span>
      </li>
    </Link>
  ) : applyFilters ? (
    <div key={myId} rootClassName={css.subcategoriesLink} onClick={applyFilters}>
      <li key={myId} className={css.subcategorySmaller}>
        {props.name}
        <span className={count > 0 ? css.countSmallerYellow : css.countSmaller}>({count})</span>
      </li>
    </div>
  ) : (
    <Link key={myId} to={subcatLink} className={css.subcategoriesLink}>
      <li key={myId} className={css.subcategorySmaller}>
        {props.name}
        <span className={count > 0 ? css.countSmallerYellow : css.countSmaller}>({count})</span>
      </li>
    </Link>
  );
};
